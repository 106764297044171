import React, { Fragment } from 'react';
import { compose } from 'recompose';

import { SessionContext } from '../components/Session/Context';
import { withAuthorization } from '../components/Session/withAuthorization';
import { withEmailVerification } from '../components/Session/withEmailVerification';
import { AuthUser } from '../types/modelsFirebase';
import { LocalitiesList } from '../components/Localities/LocalitiesList';

const HomePageBase = () => (
  <Fragment>
    <SessionContext.Consumer>
      {(authUser) =>
        authUser &&
        authUser.organizationId &&
        authUser.localityIds != undefined && (
          <div>
            <LocalitiesList organizationId={authUser.organizationId} localityIds={authUser.localityIds} />
          </div>
        )
      }
    </SessionContext.Consumer>
  </Fragment>
);

const condition = (authUser: AuthUser) => !!authUser.user;

const HomePage = compose(withEmailVerification, withAuthorization(condition))(HomePageBase);

export default () => <HomePage />;
