import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { Locality, StatsIntervalStatus, Sensor } from '../../types/models';
import { StatusBox, StatusBoxLocality } from '../StatusBox/StatusBox';
import { Theme as AugmentedTheme, CardContent, Box, Tooltip } from '@material-ui/core';
import { BoxCenterContent } from '../BoxCenterContent/BoxCenterContent';
import { supportEmailAddress } from '../../constants/supportEmailAddress';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  card: {
    width: 360,
    height: 299
  },
  cardContent: {
    height: 199,
    padding: theme.remSpacing(4, 4, 4, 6)
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.remSpacing(2),
    paddingLeft: theme.remSpacing(2)
  },
  mediaBox: {
    height: 100,
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  mediaImage: {
    height: '100%'
  },
  mediaTextBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  },
  noDataTextContainer: {
    width: 280,
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center'
  }
}));

interface LocalityCardProps {
  locality: Locality;
  sensorId: string;
}

export const LocalityCard: React.FunctionComponent<LocalityCardProps> = ({ locality, sensorId }: LocalityCardProps) => {
  const styles = useStyles(locality);

  const locationBox = () => {
    return (
      <Box className={styles.mediaBox}>
        {locality.locationImageUrl && (
          <CardMedia className={styles.mediaImage} image={locality.locationImageUrl} title="Location" />
        )}
        {locality.locationImageUrl === null && (
          <Box className={styles.mediaTextBox}>
            <Typography variant="h6">
              {Localization.getInst().localizedString(LocalizationKey.locationImageNotAvailable)}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const noDataMessage = () => {
    return (
      <BoxCenterContent>
        <div className={styles.noDataTextContainer}>
          <Typography variant="h5" component="div">
            {Localization.getInst().localizedString(
              LocalizationKey.weAreHavingIssuesFetchingDataForThisLocationAtTheMoment
            )}
          </Typography>
          <Typography variant="h6" component="div">
            {Localization.getInst().localizedString(LocalizationKey.thisMayBeDueToTheNetworkConnections)}
            <br />
            {Localization.getInst().localizedString(LocalizationKey.tryAgainLaterOrContactUsAt)}
            <br />
            {supportEmailAddress}
          </Typography>
        </div>
      </BoxCenterContent>
    );
  };

  const doNoThing = () => {
  };

  return (
    <Card className={styles.card}>
      {locality.worstCage && (
        <CardActionArea>
          <CardContent className={styles.cardContent}>
            <Tooltip title={<h3>{sensorId}</h3>} placement="top-start">
              <Typography className={styles.title}>{locality.name}</Typography>
            </Tooltip>
            <StatusBoxLocality locality={locality} statsInterval={StatsIntervalStatus.daily} onLocalityClick={doNoThing} />
          </CardContent>

          {locationBox()}
        </CardActionArea>
      )}
      {locality.worstCage == undefined && noDataMessage()}
    </Card>
  );
};
