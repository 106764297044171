import React, { useState, useEffect, Fragment } from 'react';
import { withFirebase } from '../Firebase/withFirebase';
import { FirebaseClient } from '../Firebase/Client';
import { Locality } from '../../types/models';
import { Grid, Card, Typography, Box, Fade } from '@material-ui/core';
import { LocalityCard } from '../../ui-components/LocalityCard/LocalityCard';
import * as ROUTES from '../../constants/routes';
import { CardLink } from '../../ui-components/CardLink/CardLink';
import { makeStyles } from '@material-ui/styles';
import { Theme as AugmentedTheme } from '@material-ui/core';
import { BoxCenterContent } from '../../ui-components/BoxCenterContent/BoxCenterContent';
import { Localization } from '../../localization/Localization';
import { LocalizationKey } from '../../localization/LocalizationKey';
import { supportEmailAddress } from '../../constants/supportEmailAddress';
import { ProgressBar } from '../../ui-components/ProgressBar/ProgressBar';

const useStyles = makeStyles((theme: AugmentedTheme) => ({
  localitiesListContainer: {
    minWidth: 360,
    maxWidth: 1160,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 360
    }
  },
  localitiesListTitle: {
    color: 'white',
    margin: theme.remSpacing(8, 0, 7, 34),
    [theme.breakpoints.down('sm')]: {
      margin: theme.remSpacing(8, 0, 7, 7)
    },
  },
  errorMessageContainer: {
    paddingTop: theme.remSpacing(20)
  },
  errorMessageCard: {
    width: 600,
    height: 300,
    margin: theme.remSpacing(0, 6, 6, 6)
  },
  unexpectedErrorTextContainer: {
    width: 440,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    padding: theme.remSpacing(6, 6, 6, 6)
  }
}));

interface Props {
  firebase: FirebaseClient;
  organizationId: string;
  localityIds: string[] | null;
}

const LocalitiesListBase = (props: Props) => {
  const [_initFirebase, _setInitFirebase] = useState(false);
  const [_loading, _setLoading] = useState(false);
  const [_progressBar, _setProgressBar] = useState(false);
  const [_localities, _setLocalities] = useState<Locality[] | null | undefined>(undefined);

  const updateLocalityWithLocationImageUrl = (
    localities: Locality[],
    updatedLocality: Locality,
    locationImageUrl: string | null
  ) => {
    if (localities == undefined) {
      return;
    }

    const newLocalities = [];
    for (const locality of localities) {
      if (locality.localityId == updatedLocality.localityId) {
        updatedLocality.locationImageUrl = locationImageUrl;
        newLocalities.push(updatedLocality);
      } else {
        newLocalities.push(locality);
      }
    }

    _setLocalities(newLocalities);
  };

  const getLocalityImages = (firebase: FirebaseClient, localities: Locality[]) => {
    for (const locality of localities) {
      if (locality.locationImagePath != undefined) {
        firebase
          .getLocalityImageUrl(locality.locationImagePath)
          .then((url) => {
            updateLocalityWithLocationImageUrl(localities, locality, url);
          })
          .catch(() => {
            updateLocalityWithLocationImageUrl(localities, locality, null);
          });
      } else {
        updateLocalityWithLocationImageUrl(localities, locality, null);
      }
    }
  };

  const firebaseInit = async () => {
    if (props.firebase && !_initFirebase) {
      _setInitFirebase(true);
      _setLoading(true);

      if (props.localityIds) {
        props.firebase
          .getLocalities(props.organizationId, props.localityIds)
          .then((localities) => {
            _setLoading(false);
            _setLocalities(localities);

            getLocalityImages(props.firebase, localities);

          })
          .catch(() => {
            _setLoading(false);
            _setLocalities(null);
          });
      } else {
        _setLoading(false);
        _setLocalities(null);
      }
    }
  };

  useEffect(() => {
    firebaseInit();
  });

  const styles = useStyles();

  const localitiesGrid = (localities: Locality[]) => (
    <Grid container justify="center" spacing={6}>
      {localities.map((locality) => (
        <Grid key={locality.localityId} item>
          <CardLink to={`${ROUTES.LOCALITYDETAILS}/?localityId=${locality.localityId}`}>
            <LocalityCard locality={locality} sensorId={locality.sensorId} />
          </CardLink>
        </Grid>
      ))}
    </Grid>
  );

  const unexpectedErrorMessage = (
    <BoxCenterContent className={styles.errorMessageContainer}>
      <Card className={styles.errorMessageCard}>
        <BoxCenterContent>
          <div className={styles.unexpectedErrorTextContainer}>
            <Typography variant="h3" component="div">
              {Localization.getInst().localizedString(
                LocalizationKey.weAreHavingIssuesFetchingDataForAllLocationsAtTheMoment
              )}
            </Typography>
            <Typography variant="h4" component="div">
              {Localization.getInst().localizedString(LocalizationKey.thisMayBeDueToTheNetworkConnections)}
              <br />
              {Localization.getInst().localizedString(LocalizationKey.tryAgainLaterOrContactUsAt)}
              <br />
              {supportEmailAddress}
            </Typography>
          </div>
        </BoxCenterContent>
      </Card>
    </BoxCenterContent>
  );

  const noLocalitiesMessage = (
    <BoxCenterContent className={styles.errorMessageContainer}>
      <Card className={styles.errorMessageCard}>
        <BoxCenterContent>
          <div className={styles.unexpectedErrorTextContainer}>
            <Typography variant="h3" component="div">
              {Localization.getInst().localizedString(LocalizationKey.thereAreNoLocalitiesAssignedToYourOrganization)}
            </Typography>
            <Typography variant="h4" component="div">
              {Localization.getInst().localizedString(LocalizationKey.pleaseContactUsAt)}
              <br />
              {supportEmailAddress}
            </Typography>
          </div>
        </BoxCenterContent>
      </Card>
    </BoxCenterContent>
  );

  return (
    <Fragment>
      {!_loading && !_progressBar && (
        <Typography className={styles.localitiesListTitle} variant="h1">
          {Localization.getInst().localizedString(LocalizationKey.localitiesListFishWelfare)}
        </Typography>
      )}
      <Fade in={_loading} onEnter={() => _setProgressBar(true)} onExited={() => _setProgressBar(false)} unmountOnExit>
        <ProgressBar />
      </Fade>
      <BoxCenterContent>
        <Box className={styles.localitiesListContainer}>
          {!_loading && !_progressBar && _localities == null && unexpectedErrorMessage}
          {!_loading && !_progressBar && _localities && _localities.length == 0 && noLocalitiesMessage}
          {!_loading && !_progressBar && _localities && _localities.length > 0 && localitiesGrid(_localities)}
        </Box>
      </BoxCenterContent>
    </Fragment>
  );
};

export const LocalitiesList = withFirebase(LocalitiesListBase);
